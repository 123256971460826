import * as React from 'react';
import { Restriction } from '../models/orders';
import OrderFormData, { OrderFormMode } from '../models/formData';
import { Translate, I18n } from 'react-redux-i18n';
import { Validation, ValidationResult } from '../../shared/validation/model';
import {
  numberValidation,
  requiredValidation
} from '../../shared/validation/validators';
import ValidationService from '../../shared/validation/service';
import { Contract, CurrencySymbols } from '../../orderbook/models/contracts';
import { formatFormData, getMaxDecimalsIncludingStepSizes } from '../../shared/utils/formatters';
import { NumberSpinner } from '../../shared/form/components/NumberSpinner';
import { IFormComponent } from '../../shared/form/components/FormComponent';
import OrderbookPreviewComponent from '../containers/orderbookPreviewComponent';
import DropdownList from '../../shared/form/components/DropdownList';
import { getOrderbookContracts } from '../../orderbook/selectors/contracts';
import orderBookStore from '../../orderbook/store/orderbooks';
import SanityCheckComponent from '../containers/sanityCheckComponent';
import { config } from '../../main/config';

interface State {
  formData: OrderFormData;
  correlationIds: string[];
  success: boolean;
  disabled: boolean;
  sanityChecks: boolean;
}

interface Props {
  handleSubmit: (
    form: OrderFormData & {associatedContractObjects: Contract[]},
    correlationId: string,
    validations: Validation
  ) => void;
  matchOnlyModeEnabled: boolean;
  orderbookPreviewEnabled: boolean;
  data: OrderFormData;
  selectedContractId: string;
  preselectedContractId: string;
  locale: string;
  contract: Contract;
  disabled: boolean;
  basketOrder?: boolean;
  handleOrderSuccess: (message: string) => void;
  formatFormData: (data: OrderFormData, priceDecimals: any, qtyDecimals: any, qtyStepSize: any) => OrderFormData & {associatedContractObjects: Contract[]};
  getMaxDecimalsIncludingStepSizes: (qtyDecimals: any, qtyStepSize: any) => number;
  handleInvalidInput: (result: ValidationResult) => void;
  handleNumberChange: (event: any, decimals: number) => void;
  handleChange: (event: any) => void;
  handleSuccess: (message: string) => void;
  formClear: () => void;
  onSanityCheck: (activated: boolean, warnings: string[]) => void;
  additionalValidations: Validation;
  requireSanityCheckConfirmation: boolean;
}

export default class OrderFormComponent extends React.Component<Props, State>  implements IFormComponent {
  validations: Validation;
  validationService: ValidationService;
  
  static getDerivedStateFromProps(props: Props, state: State) {
    let data = {...state.formData};
    
    // if order form has been (re)opened with new parameters (contract or mode)
    if (props.data.mode !== state.formData.mode || props.selectedContractId != state.formData.contractId) {
      data = {...props.data};
    }
    return {
      ...state,
      form: {
        ...props.data
      },
      formData: data
    };
  }
  
  constructor(props: Props) {
    super(props);
    const formData = formatFormData(props.data, true, this.props.contract.id, this._getContracts());
    this.state = {
      formData: {
        ...formData
      },
      success: false,
      correlationIds: [],
      disabled: false,
      sanityChecks: config.sanityChecksEnabled
    };
   
    this.toggleType = this.toggleType.bind(this);
    this.setSuccess = this.setSuccess.bind(this);
    this.handleOrderSuccess = this.handleOrderSuccess.bind(this);

    this.validations = {
      translationPrefix: 'order.form',
      rules: {
        price: [numberValidation],
        restriction: [requiredValidation],
        quantity: [numberValidation]
      }
    };

    const additional = this.props.additionalValidations;
    if (additional && additional.rules) {
      Object.keys(additional.rules).forEach(k => {
        if (this.validations.rules[k]) {
          this.validations.rules[k] = this.validations.rules[k].concat(additional.rules[k]);
        }
      });
    }

    this.validationService = new ValidationService();
  }

  _getContracts = () => getOrderbookContracts(orderBookStore.getState());
  
  setFormState = (formState: any) => {
    // workaround for boolean combobox
    if (formState.buy === 'true') { 
      formState.buy = true;
    }
    else if (formState.buy === 'false') { 
      formState.buy = false;
    }
    return this.setState(prevState => {
      return {
              ...prevState,
              formData: {...prevState.formData, ...formState }
          };
      }
    );
  }

  getFormState = () => {
      return formatFormData(this.state.formData, false, this.state.formData.contractId, this._getContracts());
  }

  getValidations = () => {
    return this.validations;
  }

  handleSuccess = () => {
    return this.props.handleSuccess(I18n.t('order.form.success' + this.state.formData.mode));
  }

  setSuccess(success: boolean) {
    this.setState(prevState => {
      return {
        ...prevState,
        success: success
      };
    });
  }

  toggleType(buy: boolean) {
    this.setState(prevState => {
      return {
        ...prevState,
        formData: {
          ...prevState.formData,
          buy: buy
        }
      };
    });
  }

  handleOrderSuccess(i18n: string) {
    if (this.state.success) {
      this.props.handleOrderSuccess(I18n.t(i18n));
    }
  }

  _selectContract = (contract: Contract) => {
    if (!contract) {
      return;
    }
    this.setState((prevState) => {
      return {
        ...prevState,
        formData: {...prevState.formData, contract, contractId: contract.id}
      };
    });
  }

  _contractSelection = (formData): JSX.Element => {
    if (!!formData.associatedContractObjects && formData.associatedContractObjects.length > 0) {
      return (
        <DropdownList
          list={formData.associatedContractObjects}
          itemName={(c: Contract) => c.name}
          itemId={(c: Contract) => c.id}
          itemValue={(c: Contract) => c}
          selectedItem={formData.associatedContractObjects[0]}
          onSelect={this._selectContract}
          visibleDropdown={true}
        />
      );
    } else {
      return (<><h4>{this.props.contract.productId}</h4><span>{this.props.contract.expiry.originalName}</span></>);
    }
  }

  createRestrictionOptions() {
    const orderRestrictionsString = config.orderRestrictions;
    let allowedRestrictions = Object.keys(Restriction);
    if (orderRestrictionsString) {
      const restrictions = orderRestrictionsString.trim().split(/\s*,\s*/);
      allowedRestrictions = Object.keys(Restriction).filter(r => restrictions.indexOf(r) > -1);
    }
    return allowedRestrictions.map(r => {
      return (<option key={r} value={r}>{r}</option>);
    });
  }

  _getQuantityUnit = (contract: Contract) => {
    let quantityUnit = !!contract.qtyUnit && contract.qtyUnit !== 'NONE' ? contract.qtyUnit : '';
    if (quantityUnit === 'KW' && contract.qtyDecimals === 3) {
      quantityUnit = 'MW';
    }
    return quantityUnit;
  }

  render() {
    const { formData } = this.state;
    
    const formVisible = !this.state.success; // && form.mode !== OrderFormMode.CANCEL;
    const priceDisabled = formData.mode === OrderFormMode.MATCH || formData.mode === OrderFormMode.CANCEL;
    const buySellDisabled = !this.props.basketOrder && (
    formData.mode === OrderFormMode.MATCH || formData.mode === OrderFormMode.MODIFY || formData.mode === OrderFormMode.CANCEL);
    const restrictionDisabled = 
    formData.mode === OrderFormMode.MATCH || formData.mode === OrderFormMode.MODIFY || formData.mode === OrderFormMode.CANCEL;
    const quantityDisabled = formData.mode === OrderFormMode.CANCEL;
    const contract = this._getContracts()[this.props.data.contractId];
    if (!contract || !contract.id) {
      return (<span><Translate value="order.form.contractNotExists" /></span>);
    }
    const rawFormData = formatFormData(this.state.formData, false, this.props.data.contractId, this._getContracts()); 
    const qtyUnit = this._getQuantityUnit(contract);
    return (
      <React.Fragment>
        <form style={{ display: formVisible ? 'block' : 'none' }}>
          <div className="contract-selection">
            {this._contractSelection(rawFormData)}
          </div>
          {formData.orderId ? (
            <div>
              <h4>
                <Translate value="order.form.orderId" />: {formData.orderId}
              </h4>
            </div>
          ) : null}

          <div className="mt-2 align-items-center">
            <div className="form-group">
              <label><Translate value="order.form.side" /></label>
              <select
                disabled={buySellDisabled}
                value={''+formData.buy}
                onChange={this.props.handleChange}
                name="buy"
                className="form-control"
              >{formData.buy}
                <option value="true">{I18n.t('order.form.bid')}</option>
                <option value="false">{I18n.t('order.form.ask')}</option>
              </select>
            </div>

            <div className="form-group restriction">
              <label><Translate value="order.form.restriction" /></label>
              <select
                disabled={restrictionDisabled}
                value={formData.restriction}
                onChange={this.props.handleChange}
                name="restriction"
                className="form-control"
              >
                {this.createRestrictionOptions()}
              </select>
            </div>

            <div className="form-group quantity">
              <label>
                <Translate value="order.form.quantity" />
              </label>
              <NumberSpinner
                  className="form-control"
                  name="quantity"
                  onChange={(event: any) =>
                    this.props.handleNumberChange(
                      event,
                      getMaxDecimalsIncludingStepSizes(contract.qtyDecimals, contract.qtyStepSize)
                    )
                  }
                  type="text"
                  value={formData.quantity}
                  size={5}
                  autoFocus={formVisible}
                  disabled={quantityDisabled} 
                  step={getMaxDecimalsIncludingStepSizes(contract.qtyDecimals, contract.qtyStepSize)}
                  min={0}
              />
              <div className="input-additional qtyUnit">{qtyUnit ? qtyUnit : '-'}</div>
            </div>

            <div className="form-group price">
              <label>
                <Translate value="order.form.price" />
              </label>
              <NumberSpinner
                className="form-control"
                name="price"
                onChange={(event: any) =>
                  this.props.handleNumberChange(
                    event,
                    contract.priceDecimals
                  )
                }
                disabled={priceDisabled}
                type="text"
                value={formData.price}
                size={5}
                step={contract.priceDecimals}
              />
              <div className="input-additional currency">{(contract.currency && CurrencySymbols[contract.currency]) ? CurrencySymbols[contract.currency] : '-'}</div>
            </div>
          </div>
          {this.state.sanityChecks ? (
            <SanityCheckComponent onSanityCheck={this.props.onSanityCheck} formData={rawFormData} contract={this.props.contract} />
          ) : (<></>)}
          <div className="orderbook-preview">
            {this.props.orderbookPreviewEnabled 
            ? <div hidden={!this.props.orderbookPreviewEnabled || (formData.mode !== OrderFormMode.ENTER && formData.mode !== OrderFormMode.MATCH)}>
              <OrderbookPreviewComponent  formData={rawFormData} contract={this.props.contract} />
              </div>
            : <div/>}
          </div>
        </form>
      </React.Fragment>
    );
  }
}
