import { Instrument } from '../../instruments/models/instruments';

export interface InquireContractsRequest {

}

export interface InquireContractsResponse {
    contracts: Contracts;
    last: boolean;
}

export interface Contracts {
    contract: Contract[];
}

export interface Contract {
    id: string;
    name: string;
    expiry: Expiry;
    expiry2: Expiry;
    lastTradingDay: Date;
    product: Product;
    unit: number;
    autoGenerated: boolean;
    productId: string;
    state: ContractState;
    instrumentId: string;
    qtyUnit: string;
    qtyDecimals: number;
    priceDecimals: number;
    qtyStepSize: number;
    mappedContract: string;
    mapped: boolean;
    currency: Currency;
    userPermission: any;
}

export interface Expiry {
    code: string;
    name: string;
    type: PeriodType;
    efa: boolean;
    periodStart: Date;
    periodEnd: Date;
    group: NamedExpiryGroup;
    originalName?: string;
}

export enum PeriodType {
    NAMED= 'NAMED',
    QUARTER_HOUR = 'QUARTER_HOUR',
    HALF_HOUR = 'HALF_HOUR',
    HOUR = 'DAY',
    BLOCK_2_H = 'BLOCK_2_H',
    BLOCK_4_H = 'BLOCK_4_H',
    DAY = 'DAY',
    WEEKEND = 'WEEKEND',
    WORKING_DAYS = 'WORKING_DAYS',
    BALANCE_OF_WEEK = 'BALANCE_OF_WEEK',
    WEEK = 'WEEK',
    SPLIT = 'SPLIT',
    BALANCE_OF_MONTH = 'BALANCE_OF_MONTH',
    MONTH = 'MONTH',
    QUARTER = 'QUARTER',
    SUM_WIN_SEASON = 'SUM_WIN_SEASON',
    SUM_WIN_7_5_GAS_SEASON = 'SUM_WIN_7_5_GAS_SEASON',
    YEAR = 'YEAR',
    GAS_YEAR = 'GAS_YEAR',
    EMISSION = 'EMISSION'
    
}

export enum NamedExpiryGroup {
    SPOT,
    PROMPT
}

export interface Product {
    id: string;
    name: string;
    offset: number;
    periodType: PeriodType;
    currency: Currency;
    instrument: Instrument;
    spread: number;
    emaId: string;
    externalProduct: string;
    decimals: number;
    qtyDecimals: number;
    minQty: number;
    qtyStepSize: number;
    tradingDayStartHour: number;
    tradingDayStartMinute: number;
    tradingDayEndHour: number;
    tradingDayEndMinute: number;
    lastTradingDayEndHour: number;
    lastTradingDayEndMinute: number;
    timezone: string;
    leadTimeMinutes: number;
    autoContractGenerationDepth: number;
    preventAutomaticExecution: boolean;
    collaborativeRouting: boolean;
    interProductSpread: boolean;
    efa: boolean;
    leg1Product: Product;
    leg1Quantifier: number;
    leg1QuantityToMinQty: boolean;
    leg2Product: Product;
    leg2Quantifier: number;
    leg2QuantityToMinQty: boolean;
    sendOffsetTrade: boolean;
    productDisplayCode: string;
    instrumentId: string;
    leg1ProductId: string;
    leg2ProductId: string;
    portfolioClassId: number;
    cooperativeTrading: boolean;
    qtyTradeFactor: number;
    qtyUnit: string;
    contracts: Contract[];
    contractId: string;
}

export enum Currency {
    EUR,
    GBP,
    USD,
    DKK,
    NOK,
    SEK,
    GBX,
    PER,
    CZK
}

export enum ContractState {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE'
}

export enum CurrencySymbols {
    EUR = '€',
    GBP = '£',
    USD = '$',
    DKK = 'kr',
    NOK = 'kr',
    SEK = 'kr',
    GBX = '-',
    PER = '-',
    CZK = 'Kč'
}